@import "~rsuite/lib/styles/themes/default/index.less";
// @import '~rsuite/lib/styles/themes/dark/index.less';

@base-color: #67b246;

:root {
  --primary: #67b246;
  --secondary: #b0b0b0;
  --white: #ffffff;
  --grey1: #f3f3f3;
  --grey2: #d9d9d9;
  --grey3: #b0b0b0;
  --grey4: #727272;
  --grey5: #363636;
  --grey6: #171717;
  --black: #000000;

  --background: #fff;

  --font-color: #575757;
  --font-body: "Fira Sans", san-serif;
  --font-header: "Chivo", serif;
}

// :dark {
//   --primary: #67B246;
//   --secondary: #B0B0B0;
//   --white: var(--grey2);
//   --grey6: #f3f3f3;
//   --grey5: #D9D9D9;
//   --grey4: #B0B0B0;
//   --grey3: #727272;
//   --grey2: #363636;
//   --grey1: #171717;
//   --black: #000000;
// }
